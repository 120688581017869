<template>
  <div id="app">
    <h1 class="apptitle">이모션글로벌 | MAKE GREATNESS emotion global</h1>
    <appHeader :navActive.sync="navActive" />
    <transition :css="false" @enter="navEnter" @leave="navLeave" mode="out-in">
      <appNavigation v-if="navActive" :navActive.sync="navActive" />
    </transition>
    <section id="container">
      <div id="scroll-wrap" ref="scrollWrap">
        <div id="scroll-inner">
          <transition :css="false" @enter="pageEnter" @leave="pageLeave" mode="out-in">
            <router-view :smoothScrollBar.sync="smoothScrollBar" />
          </transition>
          <!-- <app-banner ref="banner" /> -->
        </div>
        <appFooter :smoothScrollBar.sync="smoothScrollBar" />
      </div>
    </section>

    <div class="fixed-wrap">
      <!-- 플로팅 섹션 링크 -->
      <section class="main-floating-section-link">
        <div class="main-floating-link link-work">
          <router-link to="/works" class="floating-btn">
            <span class="btn-ico ico-work"></span>
            <span class="btn-txt">See More Work</span>
            <span class="btn-arr">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="arr-svg"
              >
                <path
                  d="M7 17L17 7"
                  stroke="#2F363D"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7 7H17V17"
                  stroke="#2F363D"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </router-link>
        </div>
        <div class="main-floating-link link-magazine">
          <router-link to="/magazine" class="floating-btn">
            <span class="btn-ico ico-magazine"></span>
            <span class="btn-txt">See All Story</span>
            <span class="btn-arr">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="arr-svg"
              >
                <path
                  d="M7 17L17 7"
                  stroke="#2F363D"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7 7H17V17"
                  stroke="#2F363D"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </router-link>
        </div>
      </section>
    </div>

    <!-- 커서 이미지지 -->
    <div id="cursor" ref="cursor"><span></span></div>

    <!-- 플로팅 메뉴 -->
    <section class="floating-menu">
      <!-- 퀵메뉴 -->
      <div class="floating-quickmenu _floating-menu-wrap">
        <button
          type="button"
          class="quickmenu-btn _btn-open-layer _cursor-link"
          id="quickmenu-open"
          aria-label="open menu"
          @click="quickOpen"
        >
          <span class="ico-e"><i class="ico ico-f"></i><i class="ico ico-b"></i></span>
        </button>

        <div class="quickmenu-layer">
          <div class="quckmenu-layer-inner">
            <div class="tit">이모션글로벌과<br />위대함을 만들어보세요.</div>
            <ul class="btns">
              <li><router-link to="/request" class="btn-round">프로젝트 문의</router-link></li>
              <li><a :href="url" class="btn-round" download>회사 소개서 다운로드</a></li>
            </ul>
            <button
              type="button"
              class="quickmenu-btn-close _btn-close-layer _cursor-link"
              id="quickmenu-close"
              aria-label="close menu"
              @click="quickClose"
            >
              <span class="ico-x"></span>
            </button>
          </div>
        </div>
      </div>

      <!-- 30주년 -->
      <div class="floating-anniversary _floating-menu-wrap">
        <button
          type="button"
          class="anniv-btn _btn-open-layer _cursor-link"
          id="anniv-open"
          aria-label="open 30th Anniversary"
        >
          <!-- @click="videoOpen" -->
          <span class="txt">Anniversary</span>
          <span class="dot"></span>
          <span class="ico-anniv"><i class="ico ico-f"></i><i class="ico ico-b"></i></span>
        </button>

        <div class="anniv-layer">
          <div class="bg"></div>
          <div class="anniv-layer-inner">
            <div class="anniv-cont" style="color: #fff">
              동영상 들어갑니다.
            </div>
            <button
              type="button"
              class="anniv-btn-close _btn-close-layer _cursor-link"
              id="anniv-close"
              aria-label="close 30th Anniversary"
              @click="videoClose"
            >
              <span class="ico-x"></span>
            </button>
          </div>
        </div>
      </div>
    </section>

    <div id="scroll-fake" :style="{height: `${this.contHeight}px`, width: '1264px'}"></div>
    <div class="loading" v-if="loading" @wheel.prevent>
      <div class="loading-spinner" v-if="ie11">
        <div class="circle">
          <div></div>
        </div>
      </div>
      <img v-else :src="require('@/assets/images/loading.svg')" alt="" />
    </div>
    <portal-target name="sticky-component" multiple tag="div" class="sticky-wrap" />
    <portal-target name="modal-component" multiple tag="div" class="modal-wrap" />
  </div>
</template>
<script>
  // import style
  import {layouts} from "@/utils/global-methods";
  import {apiFileDown} from "@/api";
  import {mapState, mapActions} from "vuex";
  import {TweenMax, Power0, Power3, Power4, Back, TimelineMax} from "gsap";
  import Scrollbar from "smooth-scrollbar";
  import ResizeObserver from "resize-observer-polyfill";
  import bus from "@/utils/bus";
  export default {
    name: "appDefault",
    data() {
      return {
        loading: false,
        pageChange: false,
        navActive: false,
        winScroll: [0, 0],
        smoothScrollBar: null,
        windowScrollTimer: null,
        anniv30Tl: null,
        ro: new ResizeObserver(entries => {
          //console.log('ResizeObserver');
          for (const entry of entries) {
            const {height} = entry.contentRect;
            this.AC_CONT_HEIGHT(height);
            if (this.pageChange) {
              setTimeout(() => {
                window.scrollTo(this.savedPosition.x, this.savedPosition.y);
              }, 1);

              this.pageChange = false;
            }
          }
        })
      };
    },
    computed: {
      ...mapState(["contHeight", "savedPosition"]),

      ie11() {
        return !!window.MSInputMethodContext && !!document.documentMode;
      },
      url() {
        return `${process.env.VUE_APP_API_URL}/api/v1/company`;
      }
    },
    watch: {
      "$route.path"() {
        this.pageChange = true;
        this.navActive = false;
        console.log(this.$route.name);
        const workLink = document.querySelector(".main-floating-link.link-work");
        const magazineLink = document.querySelector(".main-floating-link.link-magazine");
        this.quickClose();
        TweenMax.to(magazineLink, 0.2, {
          autoAlpha: 0,
          y: 0
        });

        TweenMax.to(workLink, 0.2, {
          autoAlpha: 0,
          y: 0
        });
      }
    },
    mounted() {
      this.ro.observe(document.querySelector("#scroll-wrap"));
      bus.$on("winScrollTo", (x, y, duration) => {
        this.smoothScrollBar.scrollTo(x, y, duration || 1000, {
          //callback: () => console.log('done!'),
          ease: Power0.easeInOut
        });
      });
      bus.$on("loading", status => {
        this.loading = status;
      });
      this.smoothScrollFn();
      setTimeout(() => {
        this.cursorMoveFn();
      }, 500);
    },
    components: {
      appHeader: layouts("app-header"),
      appNavigation: layouts("app-navigation"),
      // appBanner: layouts("app-banner"),
      appFooter: layouts("app-footer")
    },
    methods: {
      ...mapActions(["AC_CONT_HEIGHT"]),
      async fileDown() {
        try {
          const response = await apiFileDown();
          let blob = new Blob([response.data]);
          let downloadUrl = window.URL.createObjectURL(blob);
          let filename = "";
          let disposition = response.headers["content-disposition"];
          if (disposition && disposition.indexOf("attachment") !== -1) {
            let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
              matches = filenameRegex.exec(disposition);

            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, "");
            }
          }
          let a = document.createElement("a");
          if (typeof a.download === "undefined") {
            window.location.href = downloadUrl;
          } else {
            a.href = downloadUrl;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
          }
        } catch (e) {
          console.log(e);
        }
      },
      windowScrollEvt() {
        //console.log('windowScroll');
        if (this.navActive) {
          this.winScroll[0] = window.pageXOffset;
        }
        this.smoothScrollBar.scrollTop = window.pageYOffset;
        this.smoothScrollBar.scrollLeft = window.pageXOffset;
        if (document.querySelector(".tab-top")) {
          document.querySelector(
            ".tab-top"
          ).style.transform = `translateX(${-window.pageXOffset}px)`;
        }
        // if (document.querySelector("header")) {
        //   document.querySelector("header").style.transform = `translateX(${-window.pageXOffset}px)`;
        // }
      },
      quickOpen() {
        const quickmenu = document.querySelector(".floating-quickmenu");
        quickmenu.classList.add("_opened");
      },
      quickClose() {
        const quickmenu = document.querySelector(".floating-quickmenu");
        quickmenu.classList.remove("_opened");
      },
      videoOpen() {
        const anniv30th = document.querySelector(".floating-anniversary");
        anniv30th.classList.add("_opened");
        this.anniv30Tl.play();
      },
      videoClose() {
        const anniv30th = document.querySelector(".floating-anniversary");
        this.anniv30Tl.reverse();
        setTimeout(() => {
          anniv30th.classList.remove("_opened");
        }, 300);
      },
      smoothScrollFn() {
        this.smoothScrollBar?.destroy();
        const scrollArea = document.querySelector(this.navActive ? "nav" : "#container");
        this.smoothScrollBar = Scrollbar.init(scrollArea, {});
        this.smoothScrollBar.addListener(val => {
          window.scrollTo(val.offset.x, val.offset.y);
        });
        window.removeEventListener("scroll", this.windowScrollEvt);
        window.addEventListener("scroll", this.windowScrollEvt);
      },
      cursorMoveFn() {
        const anniv30th = document.querySelector(".floating-anniversary");
        const anniv30thLayerBg = anniv30th.querySelector(".anniv-layer .bg");

        this.anniv30Tl = new TimelineMax({paused: true});
        this.anniv30Tl.from(anniv30thLayerBg, 0.3, {xPercent: 100, ease: Power3.inOut});

        // 커서 이미지
        const cursor = this.$refs.cursor;
        let cursorX = 0;
        let cursorY = 0;

        // 커서 이벤트
        window.addEventListener("mousemove", function(e) {
          // console.log(e.clientX, e.clientY, cursor.style, cursor.style.transform);
          cursorX = e.clientX - cursor.offsetWidth / 2;
          cursorY = e.clientY - cursor.offsetHeight / 2;
          cursorMove();
        });

        function cursorMove() {
          cursor.style.transform = `translate(${cursorX}px, ${cursorY}px)`;
        }

        const gnbMenus = document.querySelectorAll(".new-header .nav a"); // header gnb
        const workItems = document.querySelectorAll(".work-list a.item-work"); // work item
        const magazineItems = document.querySelectorAll(".magazine-wrap .item"); // magazine
        const cursorLinks = document.querySelectorAll("._cursor-link"); // 링크 커서 (퀵메뉴)

        this.addCursorImage(gnbMenus, "cursor-style02");
        this.addCursorImage(workItems, "cursor-style03");
        this.addCursorImage(magazineItems, "cursor-hide");
        this.addCursorImage(cursorLinks, "cursor-link"); // cursor-link ? cursor-hide ?

        // footer animation
        const footer = document.querySelector(".new-footer");
        const footerCopy = footer.querySelector(".footer-animation .footer-copy");
        const footerCopyText = footerCopy.querySelectorAll("._char");

        let ftCenter = 0;
        let ftCharX = 0;
        const copySpeed = 0.1;

        document.addEventListener("mousemove", () => {
          // cursorX 전역 변수 (커서 이벤트와 공통 적용)
          footerCopyMove();
        });

        // Footer 텍스트 애니메이션
        function footerCopyMove() {
          footerCopyText.forEach(item => {
            ftCenter = cursorX - window.innerWidth / 2;
            ftCharX += (ftCenter - ftCharX) * copySpeed;
            item.style.transform = `translateX(${ftCharX / 35}px) rotateY(${ftCenter * 0.035}deg)`;
          });
        }

        const footerCopyTl = new TimelineMax();
        footerCopyTl.staggerFromTo(
          footerCopyText,
          0.3,
          {scaleY: 0},
          {scaleY: 1, ease: Back, stagger: {amount: 0.5, ease: Power3.inOut}},
          0.1
        );

        // .fromTo(
        //   footerCopyText,
        //   0.3,
        //   {scaleY: 0},
        //   {scaleY: 1, ease: Back, stagger: {amount: .5, ease: "power3.inOut"}}
        // )
      },
      addCursorImage(selector, cursorClass) {
        const cursor = this.$refs.cursor;
        selector.forEach(item => {
          item.addEventListener("mouseenter", () => cursor.classList.add(cursorClass));
          item.addEventListener("mouseleave", () => cursor.classList.remove(cursorClass));
        });
      },
      navEnter(el, done) {
        this.winScroll = [window.pageXOffset, window.pageYOffset];
        el.scrollTo(window.pageXOffset, 0);

        // navigation bg
        const bg = el.querySelector(".bg");
        TweenMax.to(bg, 0.8, {
          y: "100%",
          ease: Power4.easeInOut,
          onComplete: () => {
            if (!this.navActive) return;
            //this.scrollFake();
            this.ro.unobserve(document.querySelector("#scroll-wrap"));
            this.ro.observe(document.querySelector("nav .gnb-wrap"));
            this.smoothScrollFn();
            el.scrollTo(0, 0);
            window.scrollTo(this.winScroll[0], 0);
          }
        });

        // navigation txt
        const anime = el.querySelectorAll(".anime1");
        const anime2 = el.querySelectorAll(".anime2");
        const anime3 = el.querySelectorAll(".logo");
        TweenMax.to(anime, 0.8, {
          y: "0%",
          delay: 0.3,
          ease: Power4.easeInOut
        });
        TweenMax.to(anime2, 0.8, {
          y: "0%",
          delay: 0.3,
          ease: Power4.easeInOut
        });
        TweenMax.to(anime3, 0.8, {
          opacity: "1",
          delay: 0.3,
          ease: Power4.easeInOut,
          onComplete: () => {
            done();
          }
        });
      },
      navLeave(el, done) {
        //this.scrollFake();
        const scrollWrap = document.querySelector("#scroll-wrap");
        const scrollFake = this.$el.querySelector("#scroll-fake");
        scrollFake.style.height = `${scrollWrap.clientHeight}px`;
        this.ro.unobserve(document.querySelector("nav .gnb-wrap"));
        this.ro.observe(document.querySelector("#scroll-wrap"));
        this.smoothScrollFn();
        window.scrollTo(this.winScroll[0], this.winScroll[1]);

        // navigation bg
        const bg = el.querySelector(".bg");
        TweenMax.to(bg, 0.8, {
          y: "0%",
          delay: 0.2,
          ease: Power4.easeInOut
        });

        // navigation txt
        const anime = el.querySelectorAll(".anime1");
        const anime2 = el.querySelectorAll(".anime2");
        const anime3 = el.querySelectorAll(".logo");

        TweenMax.to(anime, 0.8, {
          y: "110%",
          ease: Power4.easeInOut
        });
        TweenMax.to(anime2, 0.8, {
          y: "-110%",
          ease: Power4.easeInOut
        });
        TweenMax.to(anime3, 0.8, {
          opacity: "0",
          ease: Power4.easeInOut,
          onComplete: () => {
            done();
          }
        });
      },
      pageEnter(el, done) {
        const fadeEl = [
          el,
          //document.querySelector(".banner-area"),
          document.querySelector("footer")
        ];
        TweenMax.fromTo(
          fadeEl,
          this.navActive ? 0 : 0.5,
          {opacity: 0},
          {
            opacity: 1,
            onComplete: () => {
              this.navActive = false;
              TweenMax.set(el, {clearProps: "all"});
              // TweenMax.set(document.querySelector(".banner-area"), {clearProps: "all"});
              this.smoothScrollFn();
              done();
            }
          }
        );
      },
      pageLeave(el, done) {
        //console.log(this.navActive);
        const fadeEl = [
          el,
          document.querySelector(".banner-area"),
          document.querySelector("footer")
        ];
        TweenMax.fromTo(
          fadeEl,
          this.navActive ? 0 : 0.5,
          {opacity: 1},
          {
            opacity: 0,
            onComplete: () => {
              done();
            }
          }
        );
      }
    }
  };
</script>
<style lang="scss">
  @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nanum+Gothic&display=swap");
  html {
    overflow-y: scroll;
  }
  .apptitle {
    position: absolute;
    visibility: hidden;
  }
  #app {
    &.nav-open {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }
  #container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background: #eee;
    #scroll-inner {
      background: #fff;
    }
    &.home {
      .scroll-inner {
        background: #f9f9f9;
      }
      .new-header {
        opacity: 0;
        visibility: hidden;
      }
      // .new-footer {
      //   opacity: 0;
      //   visibility: hidden;
      // }
    }
  }
  #scroll-wrap {
    overflow: hidden;
  }
  #scroll-inner {
    position: relative;
  }
  .contents {
    @include globalWidth;
    padding: 0 20px;
    &:before {
      content: "";
      margin-top: -1px;
      height: 1px;
      width: 1px;
      display: block;
    }
  }
  #scroll-fake {
    visibility: hidden;
    opacity: 0;
  }
  .transition-bg {
    position: fixed;
    top: 0;
    left: 0;
    height: 0;
    width: 100vw;
  }
  .scrollbar-track {
    display: none !important;
  }
  .loading {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(255, 255, 255, 0.5);
    @keyframes circle {
      0%,
      100% {
        animation-timing-function: cubic-bezier(0.45, 0, 0.9, 0.55);
      }
      0% {
        transform: translate(0, 0);
      }
      50% {
        transform: translate(0, 108px);
        animation-timing-function: cubic-bezier(0, 0.45, 0.55, 0.9);
      }
      100% {
        transform: translate(0, 0);
      }
    }

    .loading-spinner {
      width: 200px;
      height: 200px;
      display: inline-block;
      overflow: hidden;
      .circle {
        width: 100%;
        height: 100%;
        position: relative;
        transform: translateZ(0) scale(1);
        backface-visibility: hidden;
        transform-origin: 0 0;
        div {
          position: absolute;
          width: 52px;
          height: 52px;
          border-radius: 50%;
          background: #ee2c3c;
          left: 74px;
          top: 20px;
          animation: circle 1s linear infinite;
          box-sizing: content-box;
        }
      }
    }
  }

  .el-dialog__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .el-dialog {
    margin: 0 !important;
    border-radius: 20px;
    overflow: hidden;
    .el-dialog__header {
      padding: 70px 60px 0 60px;

      .el-dialog__title {
        font-weight: bold;
        font-size: 32px;
        line-height: 50px;
      }
      .el-dialog__headerbtn {
        top: 18px;
        right: 20px;
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: $red;
        .el-dialog__close {
          color: #fff !important;
        }
      }
    }

    .el-dialog__body {
      padding: 30px 60px 60px;
    }
  }

  @media print {
    #container {
      position: static;
      height: auto;
      overflow: visible !important;
      .magazine-view {
        .page-cont {
          margin-left: 0;
        }
      }
    }
    #scroll-fake {
      display: none !important;
      height: 0 !important;
    }
    header {
      position: static !important;
    }
    .banner-area {
      display: none !important;
    }
  }

  // 플로팅 폰트
  .main-floating-section-link,
  .floating-menu {
    font-family: "Montserrat", sans-serif;
  }
  // 플로팅 섹션 링크
  .main-floating-section-link {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 10;
    // z-index: 999;

    .main-floating-link {
      position: absolute;
      opacity: 0;
      // visibility: hidden;

      .floating-btn {
        position: absolute;
        left: 50%;
        bottom: -60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 20px;
        height: 76px;
        padding: 0 10px 0 10px;
        border-radius: 38px;
        background: rgba(238, 238, 238, 0.8);
        box-shadow: 0 5px 40px rgba(0, 0, 0, 0.2);
        transform: translateX(-50%);
        backdrop-filter: blur(3px);

        > span {
          display: flex;
          transition: 0.3s ease-in-out;
        }

        .btn-ico {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 56px;
          height: 56px;
          border-radius: 50%;
          background-color: #fff;
          color: #fff;
          &::after {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 34px;
            height: 34px;
            background: url("../assets/images/main/ico-e-red.svg") no-repeat center/contain;
            transform: translate(-50%, -50%) scale(1);
            transition: 0.3s;
          }

          &.ico-work::after {
            background-image: url(../assets/images/main/ico-work.svg);
          }

          &.ico-magazine::after {
            background-image: url(../assets/images/main/ico-magazine.svg);
          }
        }
        .btn-txt {
          color: #000;
          font-size: 16px;
          font-weight: 500;
          white-space: nowrap;
        }
        .btn-arr {
          position: relative;
          width: 24px;
          height: 24px;
          // background: url("/assets/images/main/ico-arrow-up.svg") no-repeat;
          transform: translate3d(0, 0, 0);
        }

        // 버튼 hover
        &:hover {
          .btn-ico {
            &::after {
              // transform: translate(-50%, -50%);
              animation: yoyoScale 0.3s alternate;
              animation-iteration-count: 4;
            }
          }
          .btn-txt {
            color: $red;
          }
          .btn-arr {
            transform: rotate(45deg);

            svg.arr-svg path {
              stroke: $red;
            }
          }
        }
      }

      // &.active {
      //   border: 1px solid red;
      //   opacity: 1;
      //   visibility: visible;
      // }
    }
  }

  @keyframes yoyoUp {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(0, -3px, 0);
    }
  }

  @keyframes yoyoDown {
    0% {
      transform: translate3d(0, 5px, 0) rotate(180deg);
    }
    100% {
      transform: translate3d(0, -5px, 0) rotate(180deg);
    }
  }

  @keyframes yoyoScale {
    0% {
      transform: translate3d(-50%, -50%, 0) scale(1);
    }
    100% {
      transform: translate3d(-50%, -50%, 0) scale(1.2);
    }
  }

  //플로팅 메뉴s
  .floating-menu {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 100%;
    pointer-events: none;
    z-index: 101;

    // 플로팅 퀵메뉴
    .floating-quickmenu {
      position: absolute;
      bottom: 40px;
      right: 80px;
      pointer-events: initial;

      .quickmenu-btn {
        display: flex;
        display: block;
        width: 68px;
        height: 68px;
        padding: 0;
        background: transparent;
        animation: yoyoUp 0.3s infinite alternate;

        .ico-e {
          display: block;
          position: relative;
          width: 68px;
          height: 68px;
          transform: translateY(0) rotateY(0deg);
          transition: 0.3s ease-in;
          transform-style: preserve-3d;

          i.ico {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: $red;
            backface-visibility: hidden;
            transform-origin: center;
            box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.1);
            background: $red url(../assets/images/common/ico-e.svg) no-repeat center;
          }
          i.ico-f {
            transform: rotateY(0deg);
          }
          i.ico-b {
            transform: rotateY(180deg);
          }
        }

        &:hover {
          .ico-e {
            transform: translateY(-15px) rotateY(180deg);
          }
          .txt {
            color: $red;
          }
        }
      }
      .quickmenu-layer {
        font-family: "Montserrat", sans-serif;

        // display: none;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 320px;
        transform: translateY(100px);
        opacity: 0;
        visibility: hidden;
        transition: 0.2s ease-in;
        .quckmenu-layer-inner {
          position: relative;
          padding: 65px 40px 40px;
          border-radius: 20px;
          background: #fff;
          letter-spacing: -2px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

          .tit {
            color: #000;
            font-family: "Montserrat", sans-serif;
            font-size: 24px;
            font-weight: 700;
            line-height: 1.7;
          }
          .btns {
            margin-top: 40px;
            li + li {
              margin-top: 20px;
            }
            .btn-round {
              display: inline-flex;
              justify-content: center;
              align-items: center;
              height: 52px;
              padding: 0 40px;
              border: 1px solid #000;
              border-radius: 28px;
              background-color: #fff;
              color: #000;
              letter-spacing: -2px;
              font-size: 18px;
              font-weight: 700;
              transition: color 0.2s;
              &:hover {
                color: $red;
              }
            }
          }
          .quickmenu-btn-close {
            position: absolute;
            top: 20px;
            right: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 45px;
            height: 45px;
            border-radius: 50%;
            background-color: $red;

            .ico-x {
              position: relative;
              display: block;
              width: 14px;
              height: 14px;
              background: url(../assets/images/common/ico-x.svg) center/contain no-repeat;
              transform: rotate(0deg);
              transition: all 0.2s ease-in-out;
            }

            &:hover {
              .ico-x {
                transform: rotate(90deg);
              }
            }
          }
        }
      }

      &._opened {
        .quickmenu-layer {
          display: block;
          transform: translateY(0px);
          opacity: 1;
          visibility: visible;

          // .quickmenu-layer-inner {
          // }
        }
      }
    }

    // 30주년
    .floating-anniversary {
      position: absolute;
      top: 0;
      right: 0;
      width: 100vw;
      height: 100vh;
      pointer-events: none;
      display: none;

      .anniv-btn {
        position: absolute;
        top: 50%;
        right: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 162px;
        height: 58px;
        padding: 9px 15px 19px;
        border-radius: 10px 10px 0 0;
        background: #000;
        transform: rotate(-90deg) translate(29px, 62px);
        transform-origin: center;
        transition: 0.2s;
        pointer-events: initial;

        &::after {
          content: "";
          position: absolute;
          bottom: -10px;
          left: 50%;
          display: block;
          width: 3000px;
          height: 20px;
          background-color: #000;
          transform: translateX(-50%);
        }

        .ico-anniv {
          position: relative;
          width: 30px;
          height: 30px;
          transform: perspective(800px) rotateX(0deg);
          transition: 0.3s ease-in;
          transform-style: preserve-3d;
          // background-color: yellow;

          i.ico {
            position: absolute;
            inset: 0;
            width: 30px;
            height: 30px;
            backface-visibility: hidden;
            transform-origin: center;
            &::after {
              content: "";
              position: relative;
              display: block;
              width: 100%;
              height: 100%;
              background: url(../assets/images/common/ico-anniv.svg) no-repeat center/contain;
              transform: rotate(90deg);
            }
          }
          i.ico-f {
            transform: rotateX(0deg) rotateY(0deg);
            &::after {
              background-image: url(../assets/images/common/ico-anniv.svg);
            }
          }
          i.ico-b {
            transform: rotateX(180deg) rotateY(0deg);
            &::after {
              background-image: url(../assets/images/common/ico-anniv-hover.svg);
            }
            // background-color: orange;
          }
        }
        .dot {
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: $red;
        }
        .txt {
          color: #fff;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: -0.05em;
          transition: 0.3s ease-in;
        }

        &:hover {
          // animation: anniv30Hover .3s alternate infinite ease-in-out;
          transform: rotate(-90deg) translate(29px, 52px);
          .ico-anniv {
            transform: perspective(800px) rotateX(180deg);
          }
          .txt {
            color: $red;
          }
        }
      }

      .anniv-layer {
        // display: none;
        position: absolute;
        top: 0;
        left: 0;
        left: 100%;
        width: 100vw;
        height: 100vh;
        // transform: translateY(-50%);
        // background-color: pink;
        pointer-events: initial;

        .bg {
          position: absolute;
          top: 0;
          left: 0;
          // left: -10px;
          width: 100vw;
          height: 100vh;
          background-color: #000;
          z-index: 1;
        }

        .anniv-layer-inner {
          position: relative;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          // background-color: skyblue;
          z-index: 2;
        }

        .anniv-btn-close {
          position: absolute;
          top: 20px;
          right: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 45px;
          height: 45px;
          border-radius: 50%;
          background-color: $red;

          .ico-x {
            position: relative;
            display: block;
            width: 14px;
            height: 14px;
            background: url(../assets/images/common/ico-x.svg) center/contain no-repeat;
            transform: rotate(0deg);
            transition: all 0.2s ease-in-out;
          }

          &:hover {
            .ico-x {
              transform: rotate(90deg);
            }
          }
        }
      }

      &._opened {
        .anniv-layer {
          // display: block;
          left: 0;
        }
      }
    }
  }

  @keyframes anniv30Hover {
    0% {
      transform: rotate(-90deg) translateY(62px);
    }
    100% {
      transform: rotate(-90deg) translateY(46px);
    }
  }

  //// 커서
  #cursor {
    position: fixed;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: $red;
    pointer-events: none;
    z-index: 201;
    * {
      pointer-events: none;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $red;
      transform: scale(1);
      transition: 0.3s;
    }

    &.cursor-link {
      background: transparent;
      opacity: 0.8;

      &::after {
        background-color: #f9f9f9;
        transition: 0.3s ease-in-out;
      }
    }

    &.cursor-style02 {
      background: transparent;
      opacity: 0.8;

      &::after {
        transition: 0.3s ease-in-out;
        transform: scale(2);
      }
    }

    // &.cursor-style02.current {
    //   &::after {
    //     // background-color: pink;
    //   }
    // }

    &.cursor-style03 {
      background: transparent;
      &::after {
        background: rgba(238, 44, 60, 0.5);
        backdrop-filter: blur(3px);
        transform: scale(4.4);
      }

      &::before {
        content: "See";
        position: absolute;
        top: 50%;
        left: 50%;
        color: #fff;
        font-size: 14px;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        text-align: center;
        transform: translate(-50%, -50%);
        z-index: 2;
      }
    }

    &.cursor-hide {
      opacity: 0;
    }
  }
</style>
